import { Container } from "react-bootstrap"
import '../assets/css/footer.css'
import { arrowr } from "./Images"

function Footer() {
    return <>
	<footer>
	    <Container fluid>
		<div>
		    <div className="custom-row">
			<div className="custom-col">
			    <ul className="ul">
				<li>
				    <a href="/monks" className="btn-with-icon"><img src={arrowr} alt="arroe" />Monks</a>
				</li>
			    </ul>
			</div>
		    </div>
			<hr />
		</div>
		<div className="footer-bottom">
		    <div>
			<p className="text-white">&copy; {new Date().getFullYear()} C¥kuza</p>
		    </div>
		    <ul className="social-links">
			<li>
			    <a href="https://njump.me/npub1zxl8m0rwxeqtegv47eclvj3me7x2k00rg3gr26re002rmq49lr5slhll8t" target="_blank" rel="noreferrer"><i class="fa-solid fa-hashtag"></i></a>
			</li>
			<li>
			    <a href="https://matrix.to/#/#cykuza:matrix.org" target="_blank" rel="noreferrer"><i class="fa fa-matrix-org fa-lg"></i></a>
			</li>
		    </ul>
		</div>
	    </Container>
	</footer>
    </>
}

export default Footer
