import { Container } from "react-bootstrap"
import '../assets/css/footer.css'
import { arrowr } from "./Images"

function MonksFooter() {
    return <>
	<footer>
	    <Container fluid>
		<div>
		    <div className="custom-row">
			<div className="custom-col">
			    <ul className="ul">
				<li>
				    <a href="/" className="btn-with-icon"><img src={arrowr} alt="arroe" />C¥kuza</a>
				</li>
			    </ul>
			</div>
		    </div>
			<hr />
		</div>
		<div className="footer-bottom">
		    <div>
			<p className="text-white">&copy; {new Date().getFullYear()} MONKS LABS</p>
		    </div>
		    <ul className="social-links">
			<li>
			    <a href="https://njump.me/npub1gpy8vwme5efn2sxv8cn4nagh8rjmw70286uul458p40q984mq0zsulp2q7" target="_blank" rel="noreferrer"><i class="fa-solid fa-hashtag"></i></a>
			</li>
			<li>
			    <a href="https://matrix.to/#/#monks:matrix.org" target="_blank" rel="noreferrer"><i class="fa fa-matrix-org fa-lg"></i></a>
			</li>
		    </ul>
		</div>
	    </Container>
	</footer>
    </>
}

export default MonksFooter
