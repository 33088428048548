import { MonksSection, MonksFooter } from "../components"
import { Helmet } from "react-helmet";

function Monks() {
    return <main>
	<Helmet>
	  <meta charSet="utf-8" />
	  <title>Monks Labs - Monks Squad Studio</title>
	  <link rel="icon" type="image/png" href="logo-monks.png" sizes="16x16" />
	  <link rel="apple-touch-icon" href="logo-monks.png" />
	  <meta name="Monks Labs" content="Monks Squad Studio" />
	</Helmet>
	{/* monks section  */}
	<MonksSection />
	{/* end monks section */}
	<MonksFooter />

    </main>
}

export default Monks
