import { memo } from "react";
import { banner } from "./Images"

function Banner()
{
    return <>
    <section className="banner-section">
    <div className="banner-text">
	<img src={banner} className="banner-img" alt="banner"/>
	<h1>C¥kuza</h1>
	<div className="flex-text">
	    <p>Time to Unite >..</p>
	</div>
    </div>
    </section>
    </>
}
export default memo(Banner);
