import { Banner, Footer } from "../components"

function Home() {
    return <main>
	{/* banner section  */}
	<Banner />
	{/* end banner section  */}
	<Footer />

    </main>
}

export default Home
