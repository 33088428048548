import { memo } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { arrow, b001, b002, b003, b004, b005, b006, b007, b008, b009 } from "./Images"
import '../assets/css/brick.css'

function MonksSection()
{
    return <>
    <section id="home" className="section common-bottom-padding bottom-padding">
	    <Container fluid>
		<Row className="align-items-center info-section">
		    <Col lg={6} md={6} className="text-overlay">
			<div className="daul-flex">
			    <h2 className="section-h2">MONKS LABS</h2>
			</div>
			<div className="daul-flex">
			    <h3 className="section-h3">Street Sectarians.</h3>
			</div>
			<p className="normal-p p-mt light-p">Unorthodox digital urban monks, sectarians of the crypto-underground syndicate.. infusing a dash of ethically charged bits into the urban hustle and bustle.</p>
			<p className="normal-p p-mt grey-p">Urban Ethics Syndicate epitomizes the independent collaboration of diverse creatives, each contributing their artistic essence to digital environment.</p>
			<p className="normal-p p-mt grey-p">Much like the C¥kuza, Monks Labs stands as adherents of the Cyberyen blockchain movement, dedicated to upholding its principles of a stringent payment system and driving its evolution towards boundless autonomy. Adding inclusivity and diversity to Cyberyen culture through the incorporation with art and uncompromising touches.</p>
			<div className="contribute">
			<a href="https://njump.me/npub1gpy8vwme5efn2sxv8cn4nagh8rjmw70286uul458p40q984mq0zsulp2q7" target="_blank" rel="noreferrer" className="normal-btn-with-icon"><span className="light-p">Nostr</span> <img src={arrow} alt="arroe" /></a>
			</div>
		    </Col>
		    <Col lg={6} md={6} className="d-md-block">
			<div className="brick-layout">
			  <div className="brick-column">
			    <img src={b001} alt="" className="d-md-none"/>
			    <img src={b002} alt="" />
			    <img src={b003} alt="" />
			  </div>
			  <div className="brick-column">
			    <img src={b004} alt="" />
			    <img src={b005} alt="" />
			    <img src={b006} alt="" />
			  </div>
			  <div className="brick-column">
			    <img src={b007} alt="" />
			    <img src={b008} alt="" />
			    <img src={b009} alt="" />
			  </div>
			</div>
		    </Col>
		</Row>
	    </Container>
	</section>
    </>
}

export default memo(MonksSection)
